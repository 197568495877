import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/Clubs/EcoClub/2024/Trash/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/Trash/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/Trash/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/Trash/4.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
// import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';
// import Sidebar from '../EcoClub/Sidebar';
import EcoAssociationSidebar2024 from '../EnvironmentDay2023/EcoAssociationSidebar2024';


const TrashintoTrump2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/Trash/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/Trash/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/Trash/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/Trash/4.webp`;
 

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source:p4,
      rows: 1,
      cols: 1,
    },
   
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      TURNING TRASH INTO TRIUMPH
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classes: 11 & 12   &  Date: 08 June 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Eco Association organised an Activity: “Turning Trash into Triumph” for the students of Classes 11 and 12 on Saturday, 8 June 2024. It was a group activity wherein the participants gave a presentation to support their views on Waste Management.
        <br></br>
        The students highlighted the importance of recycling and upcycling waste management innovatively and creatively. It served as a powerful reminder, what is trash for one can indeed become a treasure for another. Engaging in real-world issues, the students act as powerful agents of change.
        <br></br>
        The event helped the young minds to explore their creativity, enhance researching skills, engage collaboratively and present their ideas confidently. Students learnt that by embracing innovation, community collaboration and sustainability, they can transform waste from a problem into a valuable resource.
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Refuse what you do not need, Reduce what you do need and Reuse what you consume."

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoAssociationSidebar2024/>
             </Box>
             {/* <Box marginBottom={4}>
               <Sidebar />
             </Box> */}


    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default TrashintoTrump2024;